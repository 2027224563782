import React from 'react';
import myImage from './spongebob_gorilla.png';
import './App.css';

const App = () => {
  return (
    <div className="container">
      <h1 className="title">$SGORILLA</h1>
      <div className="image-container">
        <img src={myImage} alt="SpongebobGorilla" className="main-image" />
      </div>
      <p className="description">ready for Raydium and Dexscreener!</p>
      <nav className="links-container">
        <a href="https://t.me/+42c6IRnRYZMyMWJh" className="link" target="_blank" rel="noopener noreferrer">
          Telegram
        </a>
        <a href="https://twitter.com/SGORILLACOIN" className="link" target="_blank" rel="noopener noreferrer">
          Twitter
        </a>
        <a href="https://www.pump.fun" className="link" target="_blank" rel="noopener noreferrer">
          PumpFun
        </a>
      </nav>
    </div>
  );
};

export default App;